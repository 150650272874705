#four-points-below-slider{
  margin-bottom: 68px;
  .block__content{
    @include container_m($site-container - 64px);
    background-color: $totally_white;
    overflow: hidden;
    padding: 32px 32px 40px;
  }
  .quarter-block{
    @include breakpoint(min-width $tablet + 1){
      @include span(3 (gutters: 1));
      &:last-of-type{
        @include span(3 (gutters: 1) last);
      }
    }
    h3{
      padding-top: 97px;
      &:before{
        top: auto;
        bottom: calc(1em + 26px);
      }
    }
  }
}
@include breakpoint($tablet $mobile + 1){
  #four-points-below-slider{
    padding-left: 10px;
    padding-right: 10px;
    .block__content{
    }
    .quarter-block{
      @include span(1 of 2 (gutters: 0.1));
      &:nth-of-type(2n){
        @include span(1 of 2 (gutters: 0.1) last);
      }
    }
  }
}
@include breakpoint($mobile){
  #four-points-below-slider{

    .block__content{
      padding: 32px 15px 40px;
    }
    .quarter-block{
      margin-bottom: 35px;
    }
  }
}