#company-overview{
  background-color: $totally_white;
  overflow: hidden;
  padding: 60px 0;
  .block__content{
    @include container_m;
  }
  .half-left{
    @include span(5 (gutters: 4.733));
  }
  .half-right{
    @include span(5.5 (gutters: 4.733) last);
    @include prefix(1.5);
    text-align: right;
    p{
      @include prefix(1.2);
    }
  }
  .big-title{
    margin-bottom: 25px;
  }
  .default-button{
    margin-top: 18px;
  }
}
@include breakpoint($tablet){
  #company-overview{

    .block__content{
      @include container_m;
    }
    .half-left{
      @include span(12);
      max-width: 551px;
      margin-bottom: 50px;
      float: none;
    }
    .half-right{
      @include span(12 last);
      max-width: 590px;
      text-align: right;
      padding-left: 0;
      > *{
        width: 100%;
        max-width: 600px;
      }
      p{
        padding-left: 0;
      }
    }
    .big-title{
      margin-bottom: 25px;
    }
    .default-button{
      margin-top: 18px;
    }
  }
}
@include breakpoint($mobile){
  #company-overview{

    .block__content{

    }
    .half-left{
      margin: auto;
    }
    .half-right{
      padding-left: 5px;
      padding-right: 5px;
      width: calc(100% - 10px);
      margin: auto;
      float: none;
      margin-top: 40px;
      p{

      }
    }
    .big-title{
      font-size: 30px;
    }
    .default-button{
      margin-top: 18px;
    }
  }
}