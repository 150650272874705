#facilities-overview{
  padding: 60px 0;
  overflow: hidden;
  .block__content{
    @include container_m($site-container - 60px);
  }
  .wrapper{
    max-width: 540px;
  }
  .default-button{
    margin-top: 18px;
    float: left;
  }
  .images-wrapper{
    margin-top: 18px;
    float: right;
    width: 1045px;
  }
  .placeholder{
    line-height: 0;
    font-size: 0;
    color: transparent;
  }
  .big-image-wrapper{
    @include span(8.00384);
    .placeholder{
      padding-bottom: 60.258249%;
    }
  }
  .small-images-wrapper{
    @include span(3.9962 last);
    .placeholder{
      @include span(12);
      padding-bottom: 60.344827%;
    }
  }
}
@include breakpoint($desktop_second){
  #facilities-overview{

    .images-wrapper{
      width: 951px;
      padding-left: 20px;
    }
  }
}
@include breakpoint($tablet ){
  #facilities-overview{
    .block__content{
    }
    .wrapper{
      .big-title{
        font-size: 30px;
      }
    }
    .default-button{

    }
      .images-wrapper{
        width: 1045px;
        padding-left: 0;
      }
    .placeholder{
      &:before{
        display: none;
      }
    }
    .big-image-wrapper{
      max-width: 697px;
      .placeholder{

      }
    }
    .small-images-wrapper{
      float: left;
      .placeholder{
        padding-bottom: 60.344827%;
      }
    }
  }
}
@include breakpoint($mobile){
  #facilities-overview{

    .block__content{

    }
    .wrapper{

    }
    .default-button{

    }
    .images-wrapper{
      width: 100%;
    }
    .placeholder{

    }
    .big-image-wrapper{
     width: 100%;
      .placeholder{
        padding-bottom: 57.008249%;
      }
    }
    .small-images-wrapper{
      width: 100%;
      .placeholder{
        width: 50%;
        float: left;
        max-width: 348px;
        padding-bottom: 29.344827%;

      }
    }
  }
}