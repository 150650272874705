#product-info-tabs{
  .block__content{
    @include container_m(1160px, 60px);
  }
  .quicktabs-tabs{
    margin: 0;
    padding: 0 30px;
    overflow: hidden;
    li{
      float: left;
      padding: 0;
      margin: 0 37px 0 0;
      display: inline-block;
      position: relative;
      a{
        display: inline-block;
        padding: 8px 0;
        text-transform: uppercase;
        color: $main_orange;
        transition: color 0.25s ease;
        &:after{
          content: '';
          display: block;
          height: 2px;
          position: absolute;
          right: 50%;
          bottom: 0;
          left: 50%;
          background-color: $main_orange;
          transition: right 0.25s ease, left 0.25s ease;
        }
        &:hover{
          color: $highlighted_orange;
          &:after{
            left: 0;
            right: 0;
          }
        }
      }
      &.active{
        a{
          color: $darker_gray;
          &:after{
            right: 0;
            left: 0;
          }
        }
      }
    }
  }
  .quicktabs_main{
    background-color: $light_gray;
    padding: 30px;
    .view-content{
      overflow: hidden;
    }
    .third{
      @include breakpoint(min-width $mobile + 1){
        @include span(3 of 10 (gutters: 1));
        &:first-child{
          @include span(4 of 10 (gutters: 1));
        }
        &:last-child {
          @include span(3 of 10 (gutters: 1) last);
        }
      }
    }
    .table{
      width: 100%;
    }
    .table-row{
      &:first-child{
        .table-cell{
          border-top: 1px solid #d3d3d3;
        }
      }
    }
    .table-cell{
      border-bottom: 1px solid #d3d3d3;
      padding: 4px 0;
      &:first-child{
        width: 132px;
      }
    }
    [class^="icon-above"],
    [class*=" icon-above"]{
      padding-top: 28px;
      display: inline-block;
      margin-top: 8px;
      line-height: 1;
      margin-right: 14px;
      &:before{
        top: auto;
        bottom: calc(1em + 2px);
        left: 50%;
        transform: translateX(-50%);
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .view-product-media {
      &.image--block {
        .field-content {
          display: flex;
          flex-wrap: wrap;
          margin: -10px;
          &>a {
            box-sizing: border-box;
            display: block;
            padding: 10px;
            flex: 0 1 20%;
            @media only screen and (max-width: 990px) {
              flex-basis: 25%;
            }
            @media only screen and (max-width: 680px) {
              flex-basis: 33.3%;
            }
            @media only screen and (max-width: 460px) {
              flex-basis: 50%;
            }
          }
        }
      }
      &.video--block {
        .field-content {
          display: flex;
          flex-wrap: wrap;
          margin: -10px;
          @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
          }
          &>video {
            box-sizing: border-box;
            display: block;
            padding: 10px;
            flex: 0 1 33.3%;
            @media only screen and (max-width: 990px) {
              flex-basis: 50%;
            }
          }
        }
      }
    }
  }
}
@include breakpoint($tablet +1){
  #product-info-tabs{
    .block__content{
      margin-right: 0;
      margin-left: 0;
    }
  }
}
@include breakpoint($mobile){
  #product-info-tabs{
    .block__content{

    }
    .quicktabs-tabs{

      li{

        a{

          &:after{

          }
          &:hover{

            &:after{

            }
          }
        }
        &.active{
          a{

            &:after{

            }
          }
        }
      }
    }
    .quicktabs_main{
      padding: 20px;
      .view-content{

      }
      .third{
        width: 100%;
        margin-bottom: 40px;
        display: inline-block;
        &:first-child{

        }
        &:last-child {

        }
      }
      .table{

      }
      .table-row{
        &:first-child{
          .table-cell{

          }
        }
      }
      .table-cell{

        &:first-child{

        }
      }
      [class^="icon-above"],
      [class*=" icon-above"]{

        &:before{

        }
        &:last-child{

        }
      }
    }
  }
}