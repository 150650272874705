.section-blog{
  .field--type-image{
    margin-bottom: 40px;
  }
}
.page-node-2017{
  .view-content{
    width: 100%;
    display: inline-block;
  }
  .views-row{
    width: 100%;
    display: inline-block;
    margin-bottom: 25px;
    border: 1px solid #dbdbdb;
    max-width: 1100px;
    .left{
      float: left;
    }
    .right{
      float: left;
      padding: 20px;
      a{
        text-align: left;
        font-size: 25px;
        position: relative;
        line-height: 33px;
        margin: 0 0 32px;
        max-width: 754px;
        display: block;
        color: #000;
        @media(max-width: 1024px){
          max-width: 495px;
          font-size: 22px;
        }
        &:before{
          content: '';
          bottom: -5px;
          left: 0;
          position: absolute;
          background: #ff9933;
          height: 5px;
          width: 30%;
        }
      }
    }
    p{
      font-size: 13px;
    }
    //@media(min-width: 1024px){
    //  &:nth-child(3n){
    //    margin-right: 0;
    //  }
    //}
  }
}
@media(max-width: 768px){
  .page-node-2017{
    .view-content{

    }
    .views-row{
      max-width: 360px;
      margin: auto;
      display: block;
      margin-bottom: 18px;
      .left{
        float: unset;
        margin: auto;
        text-align: center;
      }
      .right{
        float: unset;
        margin: auto;
        text-align: center;
        padding: 12px;
        a{
          font-size: 18px;
          line-height: 22px;
          &:before{
            height: 3px;
            bottom: -12px;
          }
        }
      }
      p{

      }

    }
  }
}
//@media(max-width: 1024px){
//  .page-node-2017{
//    .views-row{
//      width: calc(50% - 35px);
//      a{
//
//      }
//      p{
//
//      }
//      @media(min-width: 769px){
//        &:nth-child(2n){
//          margin-right: 0;
//        }
//      }
//    }
//  }
//}
//@media(max-width: 768px){
//  .page-node-2017{
//    .views-row{
//      width: calc(100% - 18px);
//      padding: 20px 6px;
//      max-width: 350px;
//      margin: auto;
//      display: block;
//      float: unset;
//      min-height: unset;
//      margin-bottom: 15px;
//      a{
//
//      }
//      p{
//
//      }
//
//    }
//  }
//}