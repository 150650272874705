#contact-info{
  strong:nth-of-type(2){
    color: $main_orange;
    a{
      color: $totally_white;
      transition: color 0.25s ease;
      &:hover{
        color: $highlighted_orange;
      }
    }
  }
}