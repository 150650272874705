.new-category-page{
  .view-content{
    display: inline-block;
    width: 100%;
    .category-item{
      margin-bottom: 50px;
      position: relative;
      &:after{
        content:'';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        background: #9a9a9a;
        display: inline-block;
        height: 1px;
      }
      .category-image{
        text-align: center;
        a{
          height: 235px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          &:after{
            content: "";
            height: 100%;
            display: inline-block;
            vertical-align: middle;
          }
          span{
            //padding-top: 30%;
            max-width: unset;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            &:before{
              background-color: transparent;
            }
            img{
              position: relative;
            }
          }
        }
      }
      .category-title{
        display: inline-block;
        width: 100%;
        margin-top: 5px;
        min-height: 45px;
        a{
          padding-right: 40px;
        }
      }
      @include breakpoint(min-width $mobile + 1) {
        @include span(1 of 4 (gutters:0.2));
        &:nth-child(4n) {
          @include span(1 of 4 (gutters:0.2) last);
          clear: right;
        }
        &:nth-child(4n + 5) {
          clear: left;
        }
      }
    }
  }
  @include breakpoint($mobile $small_mobile + 1){
    .view-content {
      .category-item {
        @include span(1 of 2 (gutters:0.1));
        &:nth-child(2n){
          @include span(1 of 2 (gutters:0.1) last);
          clear: right;
        }
        &:nth-child(2n + 3){
          clear: left;
        }
      }
    }
  }
  @include breakpoint($small_mobile){
    .view-content {
      .category-item {
        @include span(12);
      }
    }
  }
}