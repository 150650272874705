body.page-node.html.page-node-2213{
  background-color: #fff;
  .l-content {
    max-width: 1400px;
    .wrap-ele.mb30{
      margin-bottom: 80px;
    }
    @media(max-width: 820px){
      .wrap-ele.rev{
        display: flex;
        flex-flow: column-reverse;
      }
    }
    .ask-btn{
      font-size: 45px;
      text-align: center;
    }
    .default-button{
      background-color: #f58220;
      border-color: #f58220;
      color: #fff;
      font-size: 16px;
      @media(min-width: 1024px){
        width: 100%;
      }
      max-width: 370px;
      text-align: center;
      @media(max-width: 1024px){
        max-width: 250px;
        margin: auto;
        display: block;
        font-size: 15px;
      }
      &:hover{
        background-color: #fff;
        border-color: #cccccc;
        color: #f58220;
      }
    }
    .wrap-img{

    }
    .row-images img{

    }
    .row-images{

    }

    // @include breakpoint($tablet) {


    //   .block-line-left {

    //   }
    // }
  }




}
body.page-node.html.page-node-2221,
body.page-node.html.page-node-2222{
  background-color: #fff;
  .l-content {
    max-width: 1600px;
    @media(max-width: 820px){
      .wrap-ele.rev{
        display: flex;
        flex-flow: column-reverse;
      }
    }
    .ask-btn{
      font-size: 45px;
      text-align: center;
    }
    .default-button{
      background-color: #f58220;
      border-color: #f58220;
      color: #fff;
      font-size: 16px;
      @media(min-width: 1024px){
        width: 100%;
      }
      max-width: 370px;
      text-align: center;
      @media(max-width: 1024px){
        max-width: 250px;
        margin: auto;
        display: block;
        font-size: 15px;
      }
      &:hover{
        background-color: #fff;
        border-color: #cccccc;
        color: #f58220;
      }
    }
    .wrap-img{

    }
    .row-images img{

    }
    .row-images{

    }

    @include breakpoint($tablet) {


      // .block-line-left {

      // }
    }
  }




}
body.page-node.html.page-node-2221,
body.page-node.html.page-node-2213,
body.page-node.html.page-node-2222{
  .l-content{
    margin-top: 15px;
    @media(max-width: 768px){
      margin-top: 10px;
      .wrap-ele.mt30{
        margin-top: 0;
        padding-top: 35px;
        margin-bottom: 0;
      }
      .ask-btn{
        font-size: 18px;
      }
    }
  }
}
