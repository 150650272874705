.page-node-953{
  .l-content{
    overflow: hidden;
  }
}
#block-block-18{
  position: absolute;
  img{
    object-fit: cover;
    object-position: center;
    height: 450px;
  }
}

.page-node-2004{
  .l-content{
    margin-top: 300px;
    background: #ffffff;
    position: relative;
    z-index: 99;
    padding: 50px;
    box-sizing: border-box;
    margin-bottom: 50px;
    max-width: 900px;
  }
  .view-luseisviomixanikoykladou{
    .view-content {
      display: inline-block;
      width: 100%;
      .views-row {
        @include span(1 of 4 (gutters: 0.1));
        img{
          width: 100%;
        }
        .title{
          padding: 5px;
          box-sizing: border-box;
          display: inline-block;
          width: 100%;
          background: #818286;
          color: #FFF;
        }
        &:nth-child(4n) {
          @include span(1 of 4 (gutters: 0.1) last);
        }
      }
    }
  }
}
@media(max-width: 1024px){
  .page-node-2004{
    .l-content{

    }
    .view-luseisviomixanikoykladou{
      .view-content {

        .views-row {
          @include span(1 of 3 (gutters: 0.1));
          img{

          }
          .title{

          }
          &:nth-child(3n) {
            @include span(1 of 3 (gutters: 0.1) last);
          }
        }
      }
    }
  }
}
@media(max-width: 768px){
  .page-node-2004{
    .l-content{

    }
    .view-luseisviomixanikoykladou{
      .view-content {

        .views-row {
          width: 100%;
          margin: 10px 0;
          img{

          }
          .title{

          }
          &:nth-child(3n) {
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }
}