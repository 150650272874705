.admin-menu{
  .l-main{
    .action-links,
    .tabs.tabs--primary{
      display: inline-block;
      position: fixed;
      left: 0;
      width: 120px;
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        @include span(12);
        border: 1px solid black;
        border-left: none;
        a{
          color: $darker_gray;
          background-color: #ccc;
          padding: 10px;
          text-decoration: none;
          @include span(12);
          width: calc(100% - 20px);
          transition: background-color 0.25s ease, color 0.25s ease;
          &:hover{
            color: $main_orange;
            background-color: #dfdfdf;
          }
          &.active{
            color: $saturated_orange;
          }
        }
      }
    }
    .action-links{
      top: 300px;
      z-index: 99;
    }
    .tabs.tabs--primary{
      top: 347px;
      z-index: 99;
      border: none;
      li{
        margin-bottom: 5px;
      }
    }
  }
}
