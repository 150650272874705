#products-front-page{
  @include span(12);
  padding-bottom: 60px;
  .block__content{
    @include container_m($site-container - 60px);
  }
  .view-header{
    @include span(12);
    > .tiny-title{
      float: left;
    }
    > .big-title{
      float: left;
      clear: left;
    }
    > p{
      float: right;
      position: relative;
      top: -4px;
    }
    .view-display-id-block{
      @include span(6 (gutters: 1.071));
      clear: left;
    }
    .view-display-id-block_1{
      @include span(6 (gutters: 1.071) last);
    }
    .view-custom-blocks-front-page{
      margin-top: 50px;
      position: relative;
      .placeholder{
        display: block;
        padding-bottom: 66.3522%;
        &:after{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          background-color: $totally_white;
          opacity: 0;
          transition: opacity 0.25s ease;
          z-index: 2;
        }
        &:hover{
          &:after{
            opacity: 0.2;
          }
        }
      }
      .white-box{
        position: relative;
        z-index: 5;
        display: inline-block;
        margin-top: -35px;
        clear: both;
        min-width: 70%;
        padding-bottom: 40px;
        .tiny-title{
          color: $darker_gray;
          position: relative;
          left: 0;
          transition: left 0.25s ease;
        }
        .medium-title{
          color: $main_orange;
          margin-bottom: 0;
          position: relative;
          left: 0;
          transition: color 0.25s ease, left 0.25s ease;
        }
        &:hover{
          .tiny-title{
            left: -5px;
          }
          .medium-title{
            left: 2px;
            color: $highlighted_orange;
          }
        }
      }
      .slide__content{
        position: relative;
        a:nth-child(3){
          //background: #000;
          //color: #FFFFFF;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 30px;
          clear: both;
          display: inline-block;
          position: absolute;
          background: #dedede;
          color: $main_orange;
          bottom: 0;
          left: 0;
          z-index: 99;
        }
      }
      .slick__arrow{
        position: absolute;
        bottom: 29px;
        left: 0;
        transform: translateX(-100%);
        button{
          border: none;
          background-color: $darker_gray;
          font-size: 0;
          color: transparent;
          width: 30px;
          height: 30px;
          display: block;
          padding: 0;
          cursor: pointer;
          &.sprite-angle-left{
            transform: translateY(100%);
            background-position: -117px -5px;
          }
          &.sprite-angle-right{
            transform: translateY(calc(-100% - 1px));
            background-position: -153px -5px;
          }
        }
      }
    }
  }
  .view-machinery-carousel-front-page{
    > .view-content{
      @include span(12);
      margin-top: 60px;
      .slick-slider{
        overflow: hidden;
      }
      .slick-list{
        margin-right: -60px;
      }
    }
  }
}
@include breakpoint($desktop_first){
  #products-front-page{
    padding-left: 20px;
    width: calc(100% - 20px);

    .view-header{

      .view-display-id-block{
        @include span(6 (gutters: 0.8));
      }
      .view-display-id-block_1{
        @include span(6 (gutters: 0.8) last);
      }
    }
  }
}
@include breakpoint($tablet){
  #products-front-page{
    padding-bottom: 30px;
    .block__content{
    }
    .view-header{
      > .tiny-title{
      }
      > .big-title{

      }
      > p{

      }
      .view-display-id-block{
        @include span(12);

      }
      .view-display-id-block_1{
        @include span(12);
      }
      .view-custom-blocks-front-page{
        max-width: 637px;
        margin: auto;
        display: block;
        float: none;
        margin-bottom: 40px;
        &:first-of-type{
          padding-top: 30px;
        }
        .placeholder{
          min-height: 422px;
          max-width: 637px;
          padding-bottom: unset;
          &:after{

          }
          &:hover{
            &:after{

            }
          }
        }
        .white-box{

          .tiny-title{

          }
          .medium-title{

          }
          &:hover{
            .tiny-title{

            }
            .medium-title{

            }
          }
        }
        .slick__arrow{

          button{

            &.sprite-angle-left{

            }
            &.sprite-angle-right{

            }
          }
        }
      }
    }
    .view-machinery-carousel-front-page{
      > .view-content{
        margin-top: 20px;
        .slick-slider{
        }
        .slick-list{

        }
      }
    }
  }
}
@include breakpoint($mobile){
  #products-front-page{
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100% - 10px);
    .block__content{

    }
    .view-header{

      > .tiny-title{

      }
      > .big-title{

      }
      > p{
        width: 100%;
        float: left;
      }
      .view-display-id-block{

      }
      .view-display-id-block_1{

      }
      .view-custom-blocks-front-page{

        .placeholder{
          padding-bottom: 66.3522%;
          min-height: unset;
          max-width: unset;
          &:after{


          }
          &:hover{
            &:after{

            }
          }
        }
        .white-box{
          margin-top: 0;
          margin-left: 17px;
          .tiny-title{

          }
          .medium-title{
            //text-overflow: ellipsis;
            //overflow: hidden;
            //white-space: nowrap;
            max-width: 300px;
            font-size: 18px;
          }
          &:hover{
            .tiny-title{

            }
            .medium-title{

            }
          }
        }
        .slick__arrow{
          left: 17px;
          bottom: 24px;
          button{

            &.sprite-angle-left{

            }
            &.sprite-angle-right{

            }
          }
        }
      }
    }
    .view-machinery-carousel-front-page{
      > .view-content{


        .slick-slider{

        }
        .slick-list{

        }
      }
    }
  }
}
@include breakpoint($small_mobile){
  #products-front-page{
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100% - 10px);
    .block__content{

    }
    .view-header{

      > .tiny-title{

      }
      > .big-title{

      }
      > p{

      }
      .view-display-id-block{

      }
      .view-display-id-block_1{

      }
      .view-custom-blocks-front-page{

        .placeholder{

          &:after{


          }
          &:hover{
            &:after{

            }
          }
        }
        .white-box{

          .tiny-title{

          }
          .medium-title{
            max-width: 203px;
          }
          &:hover{
            .tiny-title{

            }
            .medium-title{

            }
          }
        }
        .slick__arrow{

          button{

            &.sprite-angle-left{

            }
            &.sprite-angle-right{

            }
          }
        }
      }
    }
    .view-machinery-carousel-front-page{
      > .view-content{


        .slick-slider{

        }
        .slick-list{

        }
      }
    }
  }
}
