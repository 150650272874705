body.page-node.html.page-node-2010,
.page-node.html.page-node-2011,
.page-node.html.page-node-2012,
.page-node.html.page-node-2013,
.page-node.html.page-node-2014,
.page-node.html.page-node-2015,
.page-node.html.page-node-2016,
.page-node.html.page-node-2086,
.page-node.html.page-node-2206,
.page-node.html.page-node-2207,
.page-node.html.page-node-2208,
.page-node.html.page-node-2209,
.page-node.html.page-node-2210,
.page-node.html.page-node-2211,
.page-node.html.page-node-2212,
.page-node.html.page-node-2213,
.page-node.html.page-node-2214,
.page-node.html.page-node-2215,
.page-node.html.page-node-2216,
.page-node.html.page-node-2221,
.page-node.html.page-node-2222,
.page-node.html.page-node-2153,
.view-machinery-carousel-front-page-video {
  .wrap-ele{
    width: 100%;
    display: inline-block;
    margin-bottom: 40px;
    &.mbot5{
      margin-bottom: 3px;
    }
    &.mt30{
      margin-top: 80px;
      padding-top: 80px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        height: 8px;
        background-color: #ff9933;
      }
    }
    &.mt30.mtop0{
      margin-top: 50px;
      padding-top: 65px;
    }
    .wrap-left{
      width: 50%;
      float: left;
      &.text{
        width: calc(50% - 30px);
        margin-right: 30px;
      }
    }
    .wrap-right{
      width: 50%;
      float: right;
      &.text{
        margin-left: 30px;
        width: calc(50% - 30px);
      }
    }
    @media(max-width: 820px){
      .wrap-left{
        width: 100%;
        &.text{
          width: 100%;
          margin-right: 0px;
          margin-top: 25px;
        }
      }
      .wrap-right{
        width: 100%;
        &.text{
          margin-left: 0px;
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
  .l-content {
   // margin-top: 18%;
    margin-top: 300px;
    background: white;
    position: relative;
    //z-index: 99;
    z-index: 1;
    padding: 50px;
    box-sizing: border-box;
    margin-bottom: 50px;
  //  max-width: 900px;
    .wrap-img{
     width: 100%;
      display: inline-block;
    }
    .row-images img{
      float: left;
      margin: 11px;
      display: inline-block;
      width: calc(50% - 22px);
    }
    .row-images{
      width: 100%;
      display: inline-block;
    }

    @include breakpoint($tablet) {
      padding: 30px 20px 20px 20px;

      // .block-line-left {
      //   font-size: 28px;
      //   @media(max-width: 768px){
      //     font-size: 24px;
      //   }
      // }
    }
  }

  .pleo-content-img-left {
    @include span(6);
    margin-right: 30px;
    margin-bottom: 30px;
    &.right{
      float: right;
      margin-right: 0;
      margin-left: 30px;
    }

    @include breakpoint($tablet) {
      width: 100%;
      float: none;
      margin-right: 0;
      text-align: center;
      &.right{
        float: none;
        margin-left: 0;
      }
    }
  }

  .pdf-files-pleo {
    margin-top: 30px;
  }

  .trigger-video {
    position: relative;
    max-width: 820px;
    margin: 0 auto 70px auto;
    height: 0;
    padding-bottom: 38%;
    overflow: hidden;
    &.notb{
      img{
        filter: unset;
      }
    }

    @include breakpoint($tablet) {
      padding-bottom: 60%;
    }

    @include breakpoint($mobile) {
      padding-bottom: 65%;
      margin: 0 auto 30px auto;
    }


    &-out {
      padding-top: 40px;
      margin-top: 60px;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        max-width: 240px;
        height: 8px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        background: #ff9933;
        display: block;
      }
    }

    img,
    iframe,
    video {
      min-height: 0;
      object-fit: cover;
      position: absolute;
      width: 100%;
      max-height: 100%;
      display: block;
      top: 0;
      height: 100%;
    }

    img {
      filter: blur(4px);
      transition: all 0.3s;
      transform: scale(1.02);
    }

    &:hover {
      cursor: pointer;

        img {
          filter: blur(0);
          transform: scale(1);
        }

          .videoplaybutton {
            opacity: 1;
          }
    }

    .videoplaybutton{
      background-color: transparent;
      opacity: 0.9;
      transition: opacity 0.6s;
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include breakpoint($mobile) {
        height: 44px;
      }

      &:before {
        content: '';
        display: block;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 46px solid #ff9933;

        @include breakpoint($mobile) {
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 34px solid #ff9933;
        }
      }
    }
  }

  .pleo {
    margin-top: 50px;
    &.mtop0{
      margin-top: 20px;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    &-left {
      box-sizing: border-box;
      @include span(6 (gutters: 1/3));
      padding: 40px;
      border-top: 2px solid #eee;
      border-left: 2px solid #eee;


      @include breakpoint($tablet) {
        width: 100%;
        float: none;
        margin-right: 0;
        padding: 20px 0 0 0;
        border-left: none;
      }

      ul {
        padding-left: 0;
        margin-left: 0;

        li {
          font-size: 18px;
          color: #333;
          padding-left: 30px;
          line-height: 1.5;
          list-style: none;
          position: relative;
          margin-bottom: 6px;

          @include breakpoint($tablet) {
            font-size: 16px;
          }

          &:before {
            position: absolute;
            left: 0;
            top: 3px;
            content: '';
            border: solid #ff9933;
            width: 6px;
            height: 10px;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
          }
        }
      }
      &.full{
        width: 100%;
        margin-right: 0;
        border: 1px solid #eee;
        padding: 20px;
        p{
          &:last-of-type{
            margin-bottom: 0;
          }
        }
        @include breakpoint($tablet) {
          padding: 20px;
        }
      }
    }

    &-right {
      box-sizing: border-box;
      @include span(6 (gutters: 1/3) last);

      @include breakpoint($tablet) {
        width: 100%;
        float: none;
        text-align: center;
      }
    }
  }

  #block-block-19,
  #block-block-21,
  #block-block-26{
/*    background: url('/sites/default/files/kl_2010_c-min.jpg') left top no-repeat;
    background-size: cover;*/
    position: absolute;
    height: 450px;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  #block-block-26{
    @media(max-width: 768px){
      height: 165px;
    }

    img {
      object-position: bottom;
      @media(max-width: 768px){
        object-position: bottom right;
      }
    }
  }
  #block-block-22{
    position: absolute;
    height: 220px;
    width: 100%;
    background-color: #ff9933;
    @media(max-width: 768px){
      height: 160px;
    }
    p{
      display: none;
    }
  }
}
body.page-node.html.page-node-2012,
&.page-node.html.page-node-2013,
&.page-node.html.page-node-2014,
&.page-node.html.page-node-2015,
&.page-node.html.page-node-2016,
.page-node.html.page-node-2206,
.page-node.html.page-node-2207,
.page-node.html.page-node-2208,
.page-node.html.page-node-2209,
.page-node.html.page-node-2210,
.page-node.html.page-node-2211,
.page-node.html.page-node-2212,
.page-node.html.page-node-2213,
.page-node.html.page-node-2214,
.page-node.html.page-node-2215,
.page-node.html.page-node-2216,
.page-node.html.page-node-2221,
.page-node.html.page-node-2222,
&.page-node.html.page-node-2086{
  .l-content{
    margin-top: 97px;
    @media(max-width: 768px){
      margin-top: 49px;
    }
  }
}
body.page-node.html.page-node-2153{
  .l-content{
    margin-top: 404px;
    article{
      .hyster-benef{
        margin-top: 30px;
        padding-bottom: 75px;
        position: relative;
        margin-bottom: 88px;
        &:before{
          content: "";
          position: absolute;
          display: inline-block;
          width: 160px;
          height: 7px;
          background-color: #ff9933;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        h3{
          font-size: 24px;
          border-bottom: 4px solid #ff9933;
          text-align: center;
          display: block;
          max-width: 410px;
          margin: auto;
          padding-bottom: 4px;
          margin-bottom: 35px;
        }
        .wrap{
          padding: 25px;
          border: 1px solid #f1f1f1;
          h2{
            font-size: 18px;
          }
          &.gray{
            background-color: #f1f1f1;
          }
        }
      }
      .hyt{
        color: #000;
        font-weight: 700;
        margin-left: 22px;
        margin-top: 24px;
        display: inline-block;
        position: relative;
        border: 2px solid #ccc;
        padding: 8px 26px 8px 14px;
        &:before{
          content: '';
          position: absolute;
          display: inline-block;
          right: 5px;
          width: 18px;
          top: 50%;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-position: -9px -89px;
          background-image: url(/sites/all/themes/kolibioti/images/sprites.png);
          height: 23px;
        }
      }
    }
  }
}

@media(max-width: 768px){
  .pdf-files-pleo{
    iframe{
      width: 100%!important;
    }
  }
  body.page-node.html.page-node-2153{
    .l-content{
      margin-top: 150px;
      h2{
        font-size: 18px;
      }
      h1{
        font-size: 23px!important;
      }
      article{
        .hyster-benef{
          padding-bottom: 47px;
          margin-bottom: 15px;
          &:before{
            width: 100px;
            height: 5px;

          }
          h3{
            font-size: 17px;
            max-width: 310px;
          }
          .wrap{
            padding: 18px;
            h2{
              font-size: 15px;
            }
            &.gray{

            }
          }
        }
        .wrap-ele{
          &.rev{
            display: flex;
            flex-flow: column-reverse;
          }
          h2{
            font-size: 18px;
          }
        }
        .hyt{

          &:before{

          }
        }
      }
    }
  }
}

.page-node.html.page-node-2015 {
  .container {
    display: flex;
    gap: 80px;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 992px) {
      gap: 25px;
      &.with-text {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    .container__item {
      flex: 0 1 50%;
      & > img:not(.no-max-height) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .container__images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          flex: 0 1 50%;
          max-width: 250px;
        }
      }
      ul {
        padding-left: 0;
        margin-left: 0;
      }
      li {
        font-size: 18px;
        color: #333;
        padding-left: 30px;
        line-height: 1.5;
        list-style: none;
        position: relative;
        margin-bottom: 6px;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          content: '';
          border: solid #f93;
          width: 6px;
          height: 10px;
          border-width: 0 4px 4px 0;
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      &.button a {
        display: block;
        max-width: max-content;
        color: white;
        background-color: #f58220;
        text-transform: uppercase;
        padding: 15px 20px;
        border: 2px solid #f58220;
        text-decoration: none;
        font-weight: 700;
        transition: 0.2s;
        &:hover {
          background-color: white;
          color: #f58220;
        }
      }
      &.bordered {
        padding-top: 40px;
        padding-left: 40px;
        border-top: 2px solid #eee;
        border-left: 2px solid #eee;
      }
    }
    &.last__container {
      position: relative;
      padding: 50px 0;
      margin: 80px 0;
      &::before,
      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 5px;
        border-radius: 3px;
        background-color: #ff9933;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
    &.button {
      align-items: center;
      h2 {
        font-size: 30px;
        margin: 0;
      }
      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }
  }
}
