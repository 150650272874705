#extra-info{
  .block__title{
    margin-bottom: 6px;
  }
  .menu{
    padding: 0;
    margin: 0;
    > li{
      margin: 0;
      > a,
      > .nolink{
        color: $main_orange;
        text-transform: uppercase;
        font-size: 12px;
        line-height: calc(1em + 4px);
        padding: 4px 0;
        display: inline-block;
      }
      > a{
        transition: color 0.25s ease;
        &:hover{
          color: $highlighted_orange;
        }
      }
    }
  }
}