#related-products{
  @include container_m($site-container - 60px);
  padding-top: 53px;
  .block__title{
    margin-bottom: 31px;
  }
  .view-content{
    overflow: hidden;
    .grid-product-item{
      box-sizing: border-box;
      @include breakpoint(min-width $tablet + 1){
        @include span(4.0001 (gutters: 1.0715));
        margin-bottom: 4.50466%;
        &:nth-child(3n + 3){
          @include span(4.0001 (gutters:  1.0715) last);
        }
      }
    }
    .pager{
      margin-top: -30px;
      margin-bottom: 46px;
    }
  }
}
@include breakpoint($tablet $mobile + 1){
  #related-products{
    margin-bottom: 40px;
    .block__title{

    }
    .view-content{

      .grid-product-item{
        @include span(1 of 2 (gutters: 0.05));
        margin-bottom:  2.43902%;;
        &:nth-child(2n){
          @include span(1 of 2 (gutters:  0.05) last);
         }
      }
      .pager{
        margin-top: -30px;
        margin-bottom: 46px;
      }
    }
  }
}
@include breakpoint($mobile){
  #related-products{
    margin-bottom: 20px;
    .block__title{

    }
    .view-content{

      .grid-product-item{
        @include span(12);
        margin-bottom: 15px;
      }
      .pager{

      }
    }
  }
}